.about-section {
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 15%;
  min-height: 700px;
  background-image: url("../images/bg-dark.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &.dark-mode {
    background-color: rgba(0, 0, 0, 0.2);

    .icon {
      color: #fff; // Set the icon color for dark mode
    }
  }

  .content {
    text-align: left;
    z-index: 2;

    .social-icons,
    .mobile-social-icons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      color: white;

      a {
        .icon {
          font-size: 1.8em;
          transition: transform 0.3s ease;

          &:hover {
            color: #5000ca;
            transform: scale(1.3);
          }
        }
      }
    }

    .mobile-social-icons {
      display: none;
    }

    p {
      font-size: 1.5em;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }

    h1 {
      font-size: 5em;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
  }

  .image-wrapper {
    z-index: 2;

    .img-dark {
      width: 150px;
      border-radius: 50%;
      border: 4px solid #fff;
    }
    .img-light {
      width: 150px;
      border-radius: 50%;
      border: 4px solid #121212;
    }
  }

  @media (max-width: 768px) {
    padding-top: 150px;
    display: block;
    padding-left: 0px;
    padding-right: 0px;

    .content {
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 5%;
    }

    .image-wrapper {
      padding-left: 5%;
      width: 100%;
    }

    h1 {
      font-size: 4em !important;
      text-align: left;
    }

    p {
      text-align: left;
    }

    .social-icons {
      display: none !important;
    }

    .mobile-social-icons {
      padding-top: 10px;
      display: flex !important;
    }
  }
}

// Global styles for light and dark modes
.light-mode {
  .icon {
    color: #000; // Set the icon color for light mode
  }
}

.dark-mode {
  .icon {
    color: #fff; // Set the icon color for dark mode
  }
}
