.contact_wrapper {
  h1 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 20px;
  }

  .contact-form {
    padding-top: 20px;

    .form-flex {
      display: flex;
      gap: 15px;
      padding-bottom: 15px;

      .MuiFormControl-root {
        width: 100%;
        margin-bottom: 15px;

        .MuiFormLabel-root {
          &.label-light {
            color: #999;
          }
          &.label-dark {
            color: #ccc;
          }
        }

        .MuiFormHelperText-root {
          color: #f44336;
        }

        .MuiOutlinedInput-root {
          width: 100%;
          background-color: #fff;
          color: #050f0b;
          padding: 12px;
          border-radius: 2px;
          border: 1px solid #ccc;
          transition: border-color 0.3s ease;

          &.Mui-focused {
            border-color: #5000ca;
          }

          &.Mui-error {
            border-color: #f44336;
          }

          &.input-dark {
            background-color: #333;
            color: #ccc;
            border-color: #666;

            &.Mui-focused {
              border-color: #5000ca;
            }

            &.Mui-error {
              border-color: #f44336;
            }
          }
        }
      }
    }

    .body-form {
      margin-bottom: 15px !important;
    }

    .input-light,
    .label-light,
    .button-light {
      color: #050f0b;
      background-color: #fff;
    }

    .input-dark,
    .label-dark,
    .button-dark {
      color: #ccc;
      background-color: #333;
    }

    .MuiFormControl-root {
      width: 100%;

      .MuiFormLabel-root {
        &.label-light {
          color: #999;
        }
        &.label-dark {
          color: #ccc;
        }
      }

      .MuiFormHelperText-root {
        color: #f44336;
      }

      .MuiOutlinedInput-root {
        width: 100%;
        background-color: #fff;
        color: #050f0b;

        &.Mui-error .MuiOutlinedInput-notchedOutline {
          border-color: #f44336;
        }

        &.input-dark {
          background-color: #333;
          color: #ccc;

          &.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: #f44336;
          }
        }

        &.Mui-focused {
          &.input-dark {
            border-color: #5000ca;
          }
        }

        &.Mui-error {
          &.input-dark {
            border-color: #f44336;
          }
        }
      }
    }

    .button-light {
      background-color: white;
      color: #050f0b;
      transition:
        background-color 0.3s ease,
        color 0.3s ease;

      &:hover {
        background-color: #5000ca;
        color: white;
      }
    }

    .button-dark {
      background-color: #333;
      color: #ccc;
      transition:
        background-color 0.3s ease,
        color 0.3s ease;

      &:hover {
        background-color: #5000ca;
        color: white;
      }
    }
  }
}

/* Dark Mode */
.dark-mode {
  .contact_wrapper {
    color: #ccc;

    h1 {
      color: #ccc;
    }

    p {
      color: #999;
    }

    .contact-form {
      .input-dark,
      .label-dark,
      .button-dark {
        color: #ccc;
        background-color: #333;
      }

      .MuiOutlinedInput-root {
        background-color: #333;
        color: #ccc;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid #666;
        transition: border-color 0.3s ease;

        &.Mui-focused {
          border-color: #5000ca;
        }

        &.Mui-error {
          border-color: #f44336;
        }

        &.input-dark {
          &.Mui-focused {
            border-color: #5000ca;
          }

          &.Mui-error {
            border-color: #f44336;
          }
        }
      }

      .button-dark {
        &:hover {
          background-color: #5000ca;
          color: white;
        }
      }
    }
  }
}
