.vertical-timeline span {
  color: white;
  font-family: "Courier Prime", monospace;
}

.vertical-timeline p {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element p {
  a {
    color: #5000ca;
    display: inline-block;
    font-weight: normal;
    transition: all 0.3s ease;
  }

  a:hover {
    font-weight: bold;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  }

  color: rgb(39, 40, 34);
}

.vertical-timeline-element h3 {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element h4 {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element span {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element .vertical-timeline-element-date {
  color: white;
}

.svg-inline--fa {
  color: white;
}

@media screen and (max-width: 1170px) {
  .vertical-timeline-element .vertical-timeline-element-date {
    color: rgb(39, 40, 34);
  }
}
