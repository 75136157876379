footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;

  .icon {
    width: 1.1em !important;
    height: 1.1em !important;
    margin-right: 10px;
    transition: transform 0.3s ease;
  }

  a:hover .icon {
    color: #5000ca;
    transform: scale(1.3);
  }

  p {
    font-size: 0.8em;
  }

  &.light-mode {
    .icon {
      color: #000;
    }
  }

  &.dark-mode {
    .icon {
      color: #fff;
    }
  }
}
